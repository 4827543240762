import React from "react";
import Button from "../../common/Button";
import { FaChevronRight } from "react-icons/fa6";
import img from "../../assets/launchpad.svg";

const Launchpad = () => {
  return (
    <div className="text-center">
      <h2 className="text-secondary text-3xl md:text-6xl font-bold">Torq Launchpad</h2>
      <p className="mt-5 md:mt-6 max-w-[725px] font-medium md:text-lg mx-auto">
        Entering the realm of Torq Launchpad unveils a dynamic space where users can engage in
        Initial Coin Offerings (ICOs), securing early access to promising tokens.
      </p>
      <a href="https://launchpad.torqnetwork.com">
      <Button
        className="moveRight flex items-center gap-2 mx-auto mt-5 md:mt-6"
        style={{ borderRadius: 16 }}
      >
        Launchpad
        <FaChevronRight />
      </Button>
      </a>
      <div className="mt-6 md:mt-8">
        <div className="flex flex-col items-center relative z-10 gap-5">
          <img
            src={img}
            alt="img"
            className="rocket md:absolute z-30 w-[495px] right-[80px] top-[22px]"
          />
          <div
            className="relative z-20 lg:max-w-[500px] rounded-[40px] px-6 py-8 lg:px-10 lg:py-12"
            style={{
              boxShadow: "-5px -5px 250px 0px #FFFFFF05 inset",
              background:
                "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
            }}
          >
            <p className="text-secondary text-2xl mb-4 font-semibold">Launching Your Own ICO</p>
            <span className="md:text-lg font-medium">
              Apply to Launch: For visionary individuals or projects looking to launch their own
              ICO, Torq Launchpad offers a straightforward process.
            </span>
          </div>
          <div
            className="relative z-40 lg:mt-[-36px] lg:ml-[-200px] lg:max-w-[500px] rounded-[40px] px-6 py-8 lg:px-10 lg:py-12"
            style={{
              boxShadow: "-5px -5px 250px 0px #FFFFFF05 inset",
              background:
                "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
            }}
          >
            <p className="text-secondary text-2xl mb-4 font-semibold">
              Empowering Token Innovation
            </p>
            <span className="md:text-lg font-medium">
              Torq Launchpad serves as a catalyst for token innovation, bringing together a
              community of contributors and creators.
            </span>
          </div>
          <div
            className="relative z-50 lg:mt-[-36px] lg:max-w-[500px] rounded-[40px] px-6 py-8 lg:px-10 lg:py-12"
            style={{
              boxShadow: "-5px -5px 250px 0px #FFFFFF05 inset",
              background:
                "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
            }}
          >
            <p className="text-secondary text-2xl mb-4 font-semibold">Participating in ICOs</p>
            <span className="md:text-lg font-medium">
              Hold Torq Network Tokens: To participate in ICOs hosted on Torq Launchpad, these
              tokens serve as the entry ticket to ICO opportunities.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Launchpad;
