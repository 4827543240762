import React from "react";
import logo from "../assets/logo2.png";

function Loader() {
  return (
    <div
      className={`preloader grid place-items-center min-h-screen h-screen fixed left-0 top-0 z-[99999] w-screen bg-bg_theme`}
    >
      <div className="m-auto relative max-sm:-translate-y-5">
        <img
          src={logo}
          alt="Logo"
          className="spinner w-full max-md:size-14"
        />
      </div>
    </div>
  );
}

export default Loader;
