import "./App.css";

import { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Loader from "./common/Loader";

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {isLoading && <Loader />}
      <Route
        exact
        path="/"
        component={Home}
      />
    </>
  );
}

export default App;
