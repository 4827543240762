import React from "react";
import Button from "../common/Button";
import graph from "../assets/OBJECTS.png";
import referral from "../assets/referall.png";
import security from "../assets/security.png";
import { FaChevronRight } from "react-icons/fa6";
import gradient from "../assets/gradient1.png";

const StakeCards = () => {
  return (
    <div className="md:max-w-[80%] mx-auto relative">
      <img
        src={gradient}
        alt="gradient"
        className="absolute md:left-[-35%] top-[-38%]"
      />
      <img
        src={gradient}
        alt="gradient"
        className="absolute max-md:hidden right-[-30%] top-[-38%]"
      />
      <p
        className="text-center text-2xl md:text-4xl font-medium relative z-10 relative z-10"
        style={{ lineHeight: 1.2 }}
      >
        The Torq Staking Dapp, a decentralized application designed to revolutionize staking by
        incorporating innovative features and ensuring a secure, seamless experience for our users
      </p>
      <a href="https://stake.torqnetwork.com" className="flex justify-center mt-6 relative z-10">
        <Button className="gap-2 moveRight first-letter:rounded-[37px] border-[1px] border-[#FFFFFF] flex items-center justify-center">
          Stake Your Torq
          <FaChevronRight />
        </Button>
      </a>
      <div className="flex flex-wrap justify-center gap-6 mt-7 relative z-10">
        <div className="flex-1 max-md:min-w-full relative">
          <div className="flex flex-col items-center hover:transition-all hover:bg-[#0b0129] bg-[#0B0244] border-2 border-[#EEEDED33] rounded-[50px] p-4">
            <div className="w-40 h-40 p-3 rounded-2xl flex items-center justify-center">
              <img
                src={graph}
                alt="Graph"
              />
            </div>
          </div>
          <p className="text-center mt-2 text-secondary text-xl font-medium">Staking Rewards</p>
        </div>

        <div className="flex-1 max-md:min-w-full ">
          <div className="flex flex-col items-center hover:transition-all hover:bg-[#0b0129] bg-[#0B0244] border-2 border-[#EEEDED33] rounded-[50px] p-4 ">
            <div className="w-40 h-40 p-3 rounded-2xl flex items-center justify-center">
              <img
                src={referral}
                alt="Graph"
              />
            </div>
          </div>
          <p className="text-center mt-2 text-secondary text-xl font-medium">
            Multi-Tier Referral System
          </p>
        </div>
        <div className="flex-1 max-md:min-w-full relative z-10">
          <div className="flex flex-col items-center hover:transition-all hover:bg-[#0b0129] bg-[#0B0244] border-2 border-[#EEEDED33] rounded-[50px] p-4 ">
            <div className="h-40 p-6 rounded-2xl flex items-center justify-center">
              <img
                src={security}
                alt="Graph"
              />
            </div>
          </div>
          <p className="text-center mt-2 text-secondary text-xl font-medium">
            Security And Efficiency
          </p>
        </div>
      </div>
    </div>
  );
};

export default StakeCards;
