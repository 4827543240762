import React from "react";
import mining from "../assets/mining.png";
import gradient from "../assets/gradient1.png";

const Section6 = () => {
  return (
    <div className="py-4 relative">
      <h1 className="text-3xl md:text-6xl font-bold text-secondary mb-4">Get Start Mining</h1>
      <p className="md:max-w-[75%] md:text-xl">
        We, You, Together: We are Torq Network, let us remember that our strength lies in our unity,
        resilience, and unwavering belief in the transformative power of blockchain technology.
        Together, we thrive – because at Torq Network, it's not just about "we" or "you" – it's
        about "we, you, together.“

        <br />
        <br />
        
        Join Torq Network today and embark on a journey towards financial liberation and technological innovation.
      </p>
      <img
        src={gradient}
        alt="gradient"
        className="absolute left-0 md:left-[-28%] top-[-20px]"
      />

      <div className="flex items-center justify-center md:mt-[-40px]">
        <img
          src={mining}
          alt=""
          className="md:max-w-[70%]"
        />
      </div>
    </div>
  );
};

export default Section6;
