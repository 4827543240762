import React from "react";
import mbl3 from "../assets/mbl3.png";
import Button from "../common/Button";
import { FaChevronRight } from "react-icons/fa6";

const TorqSwap = () => {
  return (
    <div>
      <div className="flex flex-col flex-col-reverse md:flex-row items-center gap-5 md:p-2">
        <div className="w-full md:w-[40%]">
          <div className="flex flex-col justify-center items-center">
            <img
              src={mbl3}
              alt="Mobile Display"
              className="max-w-[18rem] md:max-w-sm max-md:translate-x-[-28px]"
            />

            <a href="https://swap.torqnetwork.com">
              <Button className="gap-2 moveRight flex items-center">
              Torq Swap
              <FaChevronRight />
            </Button>
            </a>
          </div>
        </div>
        <div className="w-full md:w-[60%] space-y-6">
          <h1
            className="text-3xl md:text-5xl font-bold text-secondary capitalize"
            style={{ lineHeight: 1.2 }}
          >
            We Enter A Realm Where Users Have The Power To Exchange Torq Tokens For Other
            Cryptocurrencies Effortlessly
          </h1>
          <div className="flex flex-wrap gap-3">
            <div className="w-[48.5%] space-y-4 max-md:w-full">
              <div
                className="md:min-h-[190px] h-fit grid place-items-center border-[1.5px] border-[#F432CA] rounded-3xl"
                style={{
                  padding: "24px",
                  background:
                    "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                <p className="font-bold md:text-xl text-center">Swap with TORQ Network</p>
              </div>
              <div
                className="md:min-h-[100px] h-fit grid place-items-center border-[1.5px] border-[#F432CA] rounded-3xl"
                style={{
                  padding: "24px",
                  background:
                    "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                <p className="font-bold md:text-xl text-center">Liquidity Pools</p>
              </div>
            </div>
            <div className="w-[48.5%] space-y-4 max-md:w-full">
              <div
                className="md:min-h-[100px] h-fit grid place-items-center border-[1.5px] border-[#F432CA] rounded-3xl "
                style={{
                  padding: "24px",
                  background:
                    "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                <p className="font-bold md:text-xl text-center"> Community-Driven Development</p>
              </div>
              <div
                className="md:min-h-[190px] h-fit grid place-items-center border-[1.5px] border-[#F432CA] rounded-3xl"
                style={{
                  padding: "24px",
                  background:
                    "radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                <p className="text-center md:text-xl font-bold">Secure and Reliable Transactions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TorqSwap;
