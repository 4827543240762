import React from "react";
import backgroundImage from "../../assets/header.png";
import torq from "../../assets/hero.svg";
import Button from "../../common/Button";
import { FaArrowDownLong } from "react-icons/fa6";

const Hero = () => {
  return (
    <div
      className="relative bg-cover bg-center bg-no-repeat w-full pt-16"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center justify-center h-full px-4 md:px-6 lg:px-8">
        <h1 className="text-3xl md:text-6xl font-bold text-center uppercase bg-clip-text bg-gradient-text mb-2">
          A Call for Change
        </h1>
        <p className="md:text-lg text-center">
          Join us as we embark on a journey to redefine decentralized finance.
        </p>
      </div>
      <div className="flex justify-center items-center mt-8">
        <img
          src={torq}
          className="md:max-w-[70%]"
          alt=""
        />
      </div>
      <div className="flex justify-center absolute bottom-3 md:bottom-6 left-0 right-0">
        <a href="https://torqnetwork.org/refer/?code=TQ179220446"><Button className="flex justify-center items-center gap-1">
          Get App NOW
          <div className="upDown-animation">
            <FaArrowDownLong />
          </div>
        </Button></a>
      </div>
    </div>
  );
};

export default Hero;
