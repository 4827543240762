import React, { useState } from "react";

const FAQ = () => {
  const [show, setShow] = useState({ 1: true, 2: false, 3: false });

  const handleShow = key => {
    setShow({ [key]: !show[key] });
  };

  return (
    <div className="lg:max-w-[70%] w-full mx-auto flex justify-center">
      <div className="w-full">
        <h1 className="text-center text-3xl md:text-5xl lg:text-[60px] font-[800] text-secondary">
          FAQ
        </h1>
        <div className="space-y-3 mt-4">
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              01
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">What sets Torq Network apart from other platforms?</span>
              {show["1"] && (
                <p className="mt-2 text-sm">
                  Innovative Approach: Torq Network stands out with its innovative approach to cryptocurrency mining, integrating cutting-edge technology and user-centric design to empower individuals on their journey to financial independence.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("1")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["1"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              02
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">How does Torq Network redefine the concept of mining?</span>
              {show["2"] && (
                <p className="mt-2 text-sm">
                  Effortless Decentralization: Torq Network redefines mining by offering a frictionless and decentralized experience. Through Torq's intuitive platform, users can seamlessly contribute to network security and decentralization while earning rewards effortlessly.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("2")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["2"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              03
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">How does Torq Network ensure the security of users' assets and mining activities?</span>
              {show["3"] && (
                <p className="mt-2 text-sm">
                  Robust Protection: Security is paramount at Torq Network. Leveraging state-of-the-art encryption and authentication protocols, Torq ensures the safety and integrity of users' assets and mining activities within a fortress of digital security.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("3")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["3"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              04
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">What insights do users gain from Torq Network's real-time market updates?</span>
              {show["4"] && (
                <p className="mt-2 text-sm">
                  Staying Ahead: Torq Network provides real-time market updates for the top 20 cryptocurrencies, offering valuable insights and analysis to help users stay ahead of market trends and make informed decisions about their mining activities and cryptocurrency investments.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("4")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["4"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              05
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">How does Torq Network's referral program promote collaboration and shared success?</span>
              {show["5"] && (
                <p className="mt-2 text-sm">
                  Fostering Collaboration: Torq Network's referral program fosters a culture of collaboration and abundance within the community. By inviting friends and family to join the network, users can share the benefits of mining and earn rewards together, creating a ripple effect of shared success and prosperity.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("5")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["5"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              06
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">How can users maximize their returns with Torq Network's staking opportunities?</span>
              {show["6"] && (
                <p className="mt-2 text-sm">
                 Optimizing Investments: Torq Network offers staking opportunities for users to maximize their returns while contributing to network stability and security. With flexible staking options, users can tailor their investment strategy to suit their financial goals and preferences, enhancing the decentralized ecosystem of Torq Network.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("6")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["6"] ? "-" : "+"}</div>
            </div>
          </div>
          <div className="overflow-hidden flex items-center border-2 border-cyan-400 rounded-lg shadow-md relative w-full">
            <span className="self-baseline font-medium text-xl md:text-2xl mx-2 md:mx-5 p-3">
              07
            </span>
            <p className="w-[calc(100%_-54px_-60px)] md:w-[calc(100%_-54px_-60px)] py-2 pr-2">
              <span className="md:text-lg">Why Join the Torq Network Ecosystem?</span>
              {show["7"] && (
                <p className="mt-2 text-sm">
                  Unlock Your Financial Potential: Joining the Torq Network ecosystem opens the door to a world of opportunity and financial freedom. With Torq, you gain access to innovative features such as effortless cryptocurrency mining, real-time market updates, lucrative referral programs, exciting bonus rewards, and staking options to maximize your returns. By becoming part of the Torq Network ecosystem, you join a community of forward-thinkers and pioneers shaping the future of decentralized finance. Don't miss out on the chance to unlock your financial potential – join Torq Network today and embark on a journey towards a brighter financial future.
                </p>
              )}
            </p>
            <div
              onClick={() => handleShow("7")}
              className="self-baseline cursor-pointer min-w-[60px] h-[60px] bg-[#E640CD] grid place-items-center"
            >
              <div className="text-3xl font-semibold">{show["7"] ? "-" : "+"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
