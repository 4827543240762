import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Hero from "../components/home/Hero";
import TorqNetwork from "../components/home/TorqNetwork";
import Mining from "../components/Mining";
import StakeCards from "../components/StakeCards";
import TorqSwap from "../components/TorqSwap";
import Section6 from "../components/Section6";
import FAQ from "../components/FAQ";
import Section9 from "../components/Section9";
import Launchpad from "../components/home/Launchpad";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <div className="mx-auto max-w-[1280px] px-6 w-full py-12">
        <TorqNetwork />
        <br />
        <div className="mt-10">
          <Mining />
        </div>
        <br />
        <div className="mt-10">
          <StakeCards />
        </div>
        <br />
        <div className="mt-10">
          <TorqSwap />
        </div>
        <br />
        <div className="mt-10">
          <Launchpad />
        </div>
        <br />
        <div className="mt-10">
          <Section6 />
        </div>
        <br />
        <div className="mt-10">
          <FAQ />
        </div>
        <br />
        <br />
        <div className="mt-10">
          <Section9 />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
