import React from "react";
import logo from "../assets/logo.png";
import footerBg from "../assets/footer.svg";
import { Link } from "react-router-dom";
import { FaInstagram, FaGithub, FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { CiLinkedin } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";
import { PiTelegramLogoBold } from "react-icons/pi";
import { AiOutlineYoutube } from "react-icons/ai";

const Footer = () => {
  return (
    <footer
      className="relative bg-cover bg-center bg-no-repeat w-full"
      style={{ backgroundImage: `url(${footerBg})` }}
    >
      <div className="mx-auto max-w-[1300px] px-6 w-full py-20 max-md:gap-4 flex flex-wrap items-center">
        <div className="w-full md:w-[25%] max-md:mb-4 mr-7">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="w-full md:w-[20%]">
          <div className="flex flex-col gap-5 [&>*]:text-white [&>*]:font-medium w-fit">
            <a
              href="/#"
              style={{ color: "#DF83FF" }}
            >
              Home
            </a>
            <a
              href="https://swap.torqnetwork.com"
              className="hover:underline"
            >
              Swap
            </a>
            <a
              href="https://stake.torqnetwork.com"
              className="hover:underline"
            >
              Staking Dapp
            </a>
          </div>
        </div>
        <div className="w-full md:w-[20%] self-baseline">
          <div className="flex flex-col gap-5 [&>*]:text-white [&>*]:font-medium w-fit">
            <a
              href="https://launchpad.torqnetwork.com"
              className="hover:underline"
            >
              Launchpad
            </a>
            <a
              href="https://whitepaper.torqnetwork.net"
              className="hover:underline"
            >
              Whitepaper
            </a>
          </div>
        </div>
        <div className="w-full md:w-[30%] max-md:mt-4">
          <div className="flex items-center gap-3">
          <a href="https://x.com/TheTorqNetwork"><FaXTwitter
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              <a href="https://instagram.com/TheTorqNetwork"><FaInstagram
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              <a href="https://github.com/TheTorqNetwork"><FaGithub
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              
              <a href="https://whatsapp.com/channel/0029VaTd019C1Fu2d0rFtq2n"><FaWhatsapp
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="https://t.me/TheTorqNetwork"><PiTelegramLogoBold
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              
              <a href="https://www.linkedin.com/company/torq-network/"><CiLinkedin
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="https://youtube.com/@thetorqnetwork?feature=shared"><AiOutlineYoutube
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="https://www.facebook.com/profile.php?id=61558977807127&mibextid=ZbWKwL"><RiFacebookCircleLine
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
