import React from "react";
import { FaGithub, FaInstagram, FaWhatsapp, FaXTwitter } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import {CiLinkedin } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";
import { PiTelegramLogoBold } from "react-icons/pi";
import { AiOutlineYoutube } from "react-icons/ai";

const Sidebar = ({ toggle, setToggle, links }) => {
  return (
    <div
      className={`sidebar lg:hidden fixed h-[100vh] ${toggle ? "left-0" : "left-[-300px]"} w-[300px] p-8 z-[9982] transition-all`}
    >
      <div className="w-full flex justify-end">
        <IoIosCloseCircle
          size={26}
          fill="#FFFFFF"
          className="lg:hidden cursor-pointer"
          style={{ transform: "rotateY(180deg" }}
          onClick={() => [
            setToggle(false),
            (document.querySelector("body").style.overflowY = "scroll"),
          ]}
        />
      </div>
      <div className="mt-4">
        {links?.map(itm => (
          <Link
            key={itm.to}
            to={itm.to}
            className={`mb-4 text-white capitalize block w-fit`}
          >
            {itm.title}
          </Link>
        ))}
      </div>
      <div className="mt-4 flex w-fit items-center gap-2 px-2 py-2 rounded-full bg-[#410D6B]">
      <a href="https://x.com/TheTorqNetwork"><FaXTwitter
                fill="#ffffff"
                size={21}
                className="cursor-pointer"
              /></a>
              <a href="https://instagram.com/TheTorqNetwork"><FaInstagram
                fill="#ffffff"
                size={21}
                className="cursor-pointer"
              /></a>
              <a href="https://github.com/TheTorqNetwork"><FaGithub
                fill="#ffffff"
                size={21}
                className="cursor-pointer"
              /></a>
              <a href="https://whatsapp.com/channel/0029VaTd019C1Fu2d0rFtq2n"><FaWhatsapp
                fill="#ffffff"
                size={21}
                className="cursor-pointer"
              /></a>
              <a href="https://t.me/TheTorqNetwork"><PiTelegramLogoBold
              fill="#ffffff"
              size={21}
              className="cursor-pointer"
            /></a>
            <a href="https://www.linkedin.com/company/torq-network/"><CiLinkedin
              fill="#ffffff"
              size={21}
              className="cursor-pointer"
            /></a>
            <a href="hhttps://youtube.com/@thetorqnetwork?feature=shared"><AiOutlineYoutube
              fill="#ffffff"
              size={21}
              className="cursor-pointer"
            /></a>
            <a href="https://www.facebook.com/profile.php?id=61558977807127&mibextid=ZbWKwL"><RiFacebookCircleLine
              fill="#ffffff"
              size={21}
              className="cursor-pointer"
            /></a>
      </div>
    </div>
  );
};

export default Sidebar;
