import React from "react";
import mobilePhoneImage2 from "../assets/mbl2.png";
import rotation from "../assets/rotation.svg";
import gradient from "../assets/gradient1.png";

const Mining = () => {
  return (
    <div className="flex flex-col flex-col-reverse md:flex-row-reverse items-center gap-8 md:p-5">
      <div className="w-full md:w-1/2 flex justify-center">
        <div>
          <img
            src={mobilePhoneImage2}
            alt="Mobile Display"
            className="lg:w-[85%] mx-auto"
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 space-y-6">
        <h1
          className="text-3xl md:text-5xl font-bold text-secondary capitalize"
          style={{ lineHeight: 1.2 }}
        >
          Your gateway to a revolutionary era in mining
        </h1>
        <p className="text-base md:text-lg">
          Torq Network implements a groundbreaking free mining protocol that democratizes access to
          blockchain rewards for all users.
        </p>
        <div className="grid place-items-center w-full relative z-10">
          <img
            src={gradient}
            className="absolute z-0"
            alt="gradient"
          />
          <img
            src={rotation}
            className="z-10 relative"
            alt="rotation"
          />
        </div>
      </div>
    </div>
  );
};

export default Mining;
