import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import logo from "../assets/logo.png";
import { FaBarsStaggered, FaInstagram, FaGithub, FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import {CiLinkedin } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";
import { PiTelegramLogoBold } from "react-icons/pi";
import { AiOutlineYoutube } from "react-icons/ai";

const links = [
  { to: "#", title: "Home" },
  { to: "https://swap.torqnetwork.com", title: "Swap" },
  { to: "https://stake.torqnetwork.com", title: "Staking Dapp" },
  { to: "https://launchpad.torqnetwork.com", title: "Launchpad" },
  { to: "https://whitepaper.torqnetwork.net", title: "Whitepaper" },
];

const Header = () => {
  const location = useLocation();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    setToggleSidebar(false);
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);

  return (
    <>
      <Sidebar
        links={links}
        toggle={toggleSidebar}
        setToggle={setToggleSidebar}
      />
      <header
        className="w-full px-6 max-lg:px-4"
        style={{
          background: "linear-gradient(360deg, rgb(9 6 26) 0%, rgb(93 0 84 / 42%) 100%)",
          backgroundBlendMode: "overlay",
        }}
      >
        <div className="mx-auto max-w-[1300px] w-full">
          <div className="flex w-full items-center justify-between px-4 py-5 max-lg:px-3 max-lg:py-4">
            <div className="flex gap-[10px] items-center justify-between">
              <FaBarsStaggered
                size={24}
                fill="#FFFFFF"
                className="lg:hidden cursor-pointer"
                onClick={() => [
                  setToggleSidebar(true),
                  (document.querySelector("body").style.overflowY = "hidden"),
                ]}
              />
              <Link to="/">
                <img
                  src={logo}
                  width={150}
                  height={33}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="flex items-center gap-8 max-lg:hidden">
              {links?.map((itm, idx) => (
                <a
                  key={`${itm.to}-${idx}`}
                  href={itm.to}
                  className={`md:text-lg font-medium ${idx === 0 ? "text-[#DF83FF]" : "text-white"} hover:underline hover:transition-all`}
                >
                  {itm.title}
                </a>
              ))}
            </div>
            <div className="max-md:hidden flex items-center gap-3 px-4 py-2 rounded-full bg-[#410D6B]">
              <a href="https://x.com/TheTorqNetwork"><FaXTwitter
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              <a href="https://instagram.com/TheTorqNetwork"><FaInstagram
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              <a href="https://github.com/TheTorqNetwork"><FaGithub
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              
              <a href="https://whatsapp.com/channel/0029VaTd019C1Fu2d0rFtq2n"><FaWhatsapp
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="https://t.me/TheTorqNetwork"><PiTelegramLogoBold
                fill="#ffffff"
                size={30}
                className="cursor-pointer"
              /></a>
              
              <a href="https://www.linkedin.com/company/torq-network/"><CiLinkedin
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="hhttps://youtube.com/@thetorqnetwork?feature=shared"><AiOutlineYoutube
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              <a href="https://www.facebook.com/profile.php?id=61558977807127&mibextid=ZbWKwL"><RiFacebookCircleLine
                fill="#ffffff"
                size={34}
                className="cursor-pointer"
              /></a>
              
              
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default memo(Header);
