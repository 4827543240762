import React from "react";
import Button from "../common/Button";

const Section9 = () => {
  return (
    <div>
      <h1 className="text-center text-3xl md:text-6xl font-bold text-secondary mb-6">
        Torq Network Secure Your
        <h1 className="bg-gradient-text bg-clip-text">Financial Future</h1>
      </h1>
      <p className="text-center md:text-xl max-w-[875px] mx-auto md:px-4 mb-7">
If you have any questions or would like to learn more about how Torq Network can benefit you, please don't hesitate to contact us. Together, let's shape the future of decentralized finance and pave the way for a brighter tomorrow.
      </p>
      <div className="text-center mt-4 relative">
        <input
          readOnly
          placeholder="support@torqnetwork.com"
          className="w-[870px] border-1 border-bg_primary bg-transparent p-3 rounded-l-md focus:outline-none max-md:w-full"
          style={{
            padding: "20px",
            border: "1px solid",
            borderImage: "linear-gradient(90.95deg, #E712D1 0%, #2B78E4 61.43%) 1",
          }}
        />
        <a href="mailto:support@torqnetwork.com">
          <Button
            className="absolute right-[16.5%] bottom-[10px] max-md:right-[8px] max-md:bottom-[16px]"
            style={{ borderRadius: "6px", border: "none" }}
          >
            Contact Us
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Section9;
