import React from "react";

const Button = ({ className = "", children, ...rest }) => {
  return (
    <button
      className={`${className} bg-primary text-[#ffffff] hover:bg-[#af23a8] hover:transition-all border-[1px] border-white max-sm:text-sm font-[600] px-5 py-2 md:px-6 md:py-3 rounded-[37px]`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
