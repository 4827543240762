import React from "react";
// import rotatingTokenImage from "../../assets/Cryptocurrency Exchange.png";
// import mobilePhoneImage from "../../assets/mobile.png";
import playButtonImage from "../../assets/Playbtn.png";
import gradient from "../../assets/gradient1.png";
import thumbnail from "../../assets/thumbnail.svg";

const TorqNetwork = () => {
  return (
    <div className="relative z-10">
      <img
        src={gradient}
        alt="gradient"
        className="absolute left-0 md:left-[-20%] top-4"
      />
      <img
        src={gradient}
        alt="gradient"
        className="absolute max-md:hidden right-[-20%] top-4"
      />
      <div className="md:max-w-[80%] mx-auto z-10 relative">
        <p
          className="text-5xl max-md:text-2xl text-center font-medium mb-2"
          style={{ lineHeight: 1.2 }}
        >
          Let's shape the future of blockchain technology with Torq ecosystem
        </p>
        <p className="md:text-xl text-center my-8">
          A promise of free mining, decentralized applications endless possibilities for growth and
          prosperity. Our mission is resolute: to revolutionize the blockchain industry and unlock
          boundless opportunities for individuals and businesses worldwide.
        </p>
        {/* <div className="relative bg-[#0B0244] overflow-hidden border-[1px] border-[#FFFFFF] rounded-[63px] ">
          <div class="container mx-auto p-4 lg:px-20">
            <div class="grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
              <div class="text-center lg:text-left">
                <h1
                  class="text-3xl md:text-6xl font-bold max-md:mt-4"
                  style={{ lineHeight: 1.5 }}
                >
                  TORQ NETWORK
                </h1>
                <img
                  src={rotatingTokenImage}
                  alt="Rotating Token"
                />
              </div>
              <div class="relative">
                <img
                  src={mobilePhoneImage}
                  alt="Mobile Phone"
                  class="w-full"
                />
                <img
                  src={playButtonImage}
                  alt="Play Button"
                  class="max-md:hidden absolute top-0 left-[-60px] right-[80%] bottom-0 m-auto w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] transform translate-x-[-6%] -translate-y-[11%] lg:translate-x-0 lg:translate-y-0"
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="relative thumbnail">
          <img
            src={playButtonImage}
            className="max-md:size-[60px] play absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            alt=""
          />
          <img
            src={thumbnail}
            alt="thumbnail"
            className="play-cursor"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default TorqNetwork;
